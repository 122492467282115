import { AnimatePresence, motion, useTransform } from 'framer-motion'
import React, {
  createElement,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react'
import Box from '../../../components/Box'
import Button from '../../../components/Button'
import LottieAnima from '../../../components/LottieAnima'
import { Media, responsive } from '../../../contexts/responsive'
// import withLazyload from '../../../utils/withLazyload'

const EnterButton = ({
  right,
  bottom,
  to,
  href,
  deskBottom,
  left,
  deskLeft,
  index,
  trackerName,
  ...props
}) => (
  <Button.White
    fontSize={responsive('3vw', '1.67vw')}
    position="absolute"
    height="auto"
    py={responsive('1.25vw', '0.73vw')}
    px={responsive('3.25vw', '1.67vw')}
    left={responsive(
      right ? '42.25%' : left || '12.5%',
      index % 2 ? deskLeft || '11.75%' : deskLeft || '61%'
    )}
    bottom={responsive(bottom || '8%', deskBottom || '33%')}
    href={href}
    to={to}
    trackerName={trackerName}
    disabled={!href && !to}
    {...props}
  >
    {to || href ? '點擊進入' : '即將上線'}
  </Button.White>
)

const Base = ({
  src,
  i,
  deskSrc,
  index,
  left,
  deskLeft,
  to,
  href,
  right,
  bottom,
  quote,
  deskQuote,
  deskBottom,
  trackerName,
  lottie,
  pageProgress,
  intro = 20,
  quote2,
}) => {
  const first = useRef(index === 0)
  const lottieRef = useRef()
  // const opacity = useTransform(pageProgress, [-0.2, 0], [0, 1])
  const [isActive, setIsActive] = useState(false)
  const [q2isActive, setQ2IsActive] = useState(false)
  const lottieTimeline = useTransform(pageProgress, [-1, 1], [0, 60])
  useEffect(() => {
    const handleChange = () => {
      const t = lottieTimeline.get()
      if (lottieRef.current) {
        lottieRef.current.goToAndStop(t, true)
      }
      if (t > intro) {
        setIsActive(true)
        if (quote2) {
          setQ2IsActive(t > quote2.intro)
        }
      } else {
        setIsActive(false)
      }
    }
    if (first.current) {
      const getLottie = () => {
        if (lottieRef.current) {
          const t = lottieTimeline.get()
          lottieRef.current.playSegments([0, t], true)
          first.current = false
        } else {
          setTimeout(getLottie, 100)
        }
      }
      getLottie()
    }
    return lottieTimeline.onChange(handleChange)
  }, [intro, quote2])
  // const prevActive = useRef()
  // const [show, setQuote] = useState()
  // useEffect(() => {
  //   if (lottieRef.current) {
  //     if (active && !prevActive.current) {
  //       setTimeout(() => {
  //         if (lottieRef.current.playSegments) {
  //           lottieRef.current.playSegments((frames[anchor] ?? defaultframes).active[direction], true)
  //         }
  //       }, direction === 'down' ? timeout : 0)
  //     }
  //     if (!active && prevActive.current) {
  //       if (lottieRef.current.playSegments) {
  //         lottieRef.current.playSegments((frames[anchor] ?? defaultframes).inactive[direction], true)
  //       }
  //     }
  //   }
  //   if (active) {
  //     setTimeout(() => setQuote(true), 1800)
  //   }
  //   prevActive.current = active
  // }, [active, direction, timeout])
  // console.log(i)
  const data = useMemo(() => {
    const d = {
      deskQuote,
      quote,
      href,
      to,
      trackerName,
      bottom,
      deskBottom,
    }
    if (quote2 && q2isActive) {
      Object.keys(d).forEach((k) => {
        if (quote2[k]) {
          d[k] = quote2[k]
        }
      })
    }
    return d
  }, [deskQuote, href, q2isActive, quote, quote2, to, trackerName])
  return (
    <>
      <Media greaterThanOrEqual="lg">
        {createElement(LottieAnima, {
          src: lottie?.desktop?.publicURL,
          ratio: 1920 / 984,
          autoplay: false,
          loop: false,
          ref: lottieRef,
        })}
        <Box.FullAbs
          backgroundImage={`url(${deskSrc})`}
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
          transform="translateZ(0)"
        />
        <AnimatePresence>
          {isActive && (
            <Box.FullAbs
              key={data.deskQuote}
              as={motion.div}
              backgroundImage={`url(${data.deskQuote})`}
              backgroundSize="cover"
              backgroundRepeat="no-repeat"
              transform="translateZ(0)"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <EnterButton
                index={i}
                trackerName={data.trackerName}
                to={data.to}
                href={data.href}
                right={right}
                bottom={data.bottom}
                left={left}
                deskLeft={deskLeft}
                deskBottom={data.deskBottom}
              />
            </Box.FullAbs>
          )}
        </AnimatePresence>
      </Media>
      <Media lessThan="lg">
        {createElement(LottieAnima, {
          src: lottie?.mobile?.publicURL,
          ratio: 827 / 1235,
          autoplay: false,
          loop: false,
          ref: lottieRef,
        })}
        <Box.FullAbs
          backgroundImage={`url(${src})`}
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
          transform="translateZ(0)"
        />
        {isActive && (
          <Box.FullAbs
            key={data.quote}
            backgroundImage={`url(${data.quote})`}
            backgroundSize="cover"
            backgroundRepeat="no-repeat"
            transform="translateZ(0)"
            as={motion.div}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <EnterButton
              trackerName={data.trackerName}
              right={right}
              left={left}
              href={data.href}
              to={data.to}
              bottom={data.bottom}
            />
          </Box.FullAbs>
        )}
      </Media>
    </>
  )
}

export default Base
