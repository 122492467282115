import React, { forwardRef, useEffect, useRef } from 'react'
import loadable from '@loadable/component'
import useSWR from 'swr'
import { AspectRatio, useMergeRefs } from '@chakra-ui/react'

import Box from './Box'
import withLazyload from '../utils/withLazyload'

const Lottie = loadable.lib(() => import('lottie-web'))

const LottieAnia = forwardRef(({
  lottie,
  autoplay = true,
  data,
  loop = true,
  onLoad = () => {},
  onComplete = () => {},
  children,
}, ref) => {
  const innterRef = useRef()
  useEffect(() => {
    if (!innterRef.current.children.length) {
      setTimeout(() => {
        const anima = lottie.loadAnimation({
          container: innterRef.current, // the dom element
          renderer: 'canvas',
          loop,
          autoplay,
          animationData: data, // the animation data
        });
        anima.addEventListener('data_ready', onLoad(anima))
        anima.onComplete = onComplete
        if (ref) {
          ref.current = anima
        }
      })
    }
  }, [data, loop, autoplay, lottie, onLoad, onComplete])
  return (
    <div>
      <Box.FullAbs ref={innterRef} />
      {children && <Box.FullAbs>{children}</Box.FullAbs>}
    </div>
  )
})

const LottieAnima = forwardRef(({ src, ratio = 1, loop, onLoad, onComplete, children, autoplay, ...props }, ref) => {
  const { data } = useSWR(src)
  return (
    <AspectRatio ratio={ratio} {...props}>
      <Lottie>
        {({ default: lottie }) => data ? (
          <LottieAnia
            lottie={lottie}
            data={data}
            loop={loop}
            onLoad={onLoad}
            onComplete={onComplete}
            autoplay={autoplay}
            ref={ref}
          >{children}</LottieAnia>
        ) : null}
      </Lottie>
    </AspectRatio>
  )
})

export default LottieAnima
